<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required"
          >
            <b-form-group
              label="price"
              label-for="price"
            >
              <b-form-input
                id="price"
                v-model="engineerForm.price"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="price"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="cost"
            rules="required"
          >
            <b-form-group
              label="cost"
              label-for="cost"
            >
              <b-form-input
                id="cost"
                v-model="engineerForm.cost"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="cost"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="SetMony()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="SetMony()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {},
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionsStatusId: [],
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const engineerForm = ref({
      name: '',
      email: '',
      status_id: [],
    })
    return {
      getValidationState,
      engineerForm,
    }
  },
  methods: {
    SetMony() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')

        Object.keys(this.engineerForm).forEach(key => {
          if (Array.isArray(this.engineerForm[key])) {
            this.engineerForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, this.engineerForm[key])
          }
        })

        axios
          .post(`/audit/${this.$route.params.id}/set-money`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'set-money' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
